@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: "Montserrat";
  src: url("../public/assets/fonts/Montserrat-Regular.ttf");
}
@font-face {
  font-family: "MontserratExtraBold";
  src: url("../public/assets/fonts/Montserrat-ExtraBold.ttf");
}
@layer base {
  html {
    font-family: Montserrat , system-ui, sans-serif;
  }
}
